.card div[class*='headerWrapper'] {
    height: auto;
    flex-direction: column;
}

.totalWeightWithButtonWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: var(--sn-space-2);
    width: 100%;
    text-align: center;
}

@media (min-width: 768px) {
    .totalWeightWithButtonWrapper {
        width: auto;
        flex-direction: row;
        align-items: center;
    }
}

@media (min-width: 1200px) {
    .card div[class*='headerWrapper'] {
        min-height: 60px;
        flex-direction: row;
    }
}
