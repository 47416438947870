.itemsWrapper {
    border-radius: var(--sn-space-1);
    max-height: 500px;
    overflow-y: auto;
}

.itemRow {
    position: relative;
    grid-template-columns: minmax(350px, auto) 250px 100px !important;
    grid-template-areas: 'productData qtyData priceData';
    width: fit-content !important;
    min-width: 100%;
}

.itemRow.disabled {
    background: var(--sn-grey-50);
    color: var(--sn-grey-300);
}

.itemRow .productData {
    grid-area: productData;
    flex-direction: row;
}

.itemRow .productData > div:last-child {
    flex-shrink: 1;
}

.itemRow .productData .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 62px;
    flex-shrink: 0;
    margin-left: calc(var(--sn-space-1) * -1);
}

.itemRow .qtyData {
    grid-area: qtyData;
    justify-content: flex-start;
}

.itemRow .qtyData input {
    width: 100px;
}

.itemRow .priceData {
    grid-area: priceData;
}

.checkIcon {
    font-size: var(--sn-title-md-font-size);
    color: var(--sn-success);
}

@media (min-width: 576px) and (max-width: 991px) {
    .modal {
        max-width: 95%;
    }
}

@media (min-width: 768px) {
    .itemRow .productData .actions {
        margin-left: calc(var(--sn-space-2) * -1);
    }
}

@media (min-width: 1024px) {
    .itemRow .productData .actions {
        margin-left: calc(var(--sn-space-3) * -1);
    }
}
