.wrapper {
    position: relative;
    width: 100%;
}

.wrapper input {
    width: 100%;
}

.spinner {
    position: absolute;
    inset: 0;
    margin: auto;
}

.isInvalid {
    border-color: var(--sn-danger) !important;
}
