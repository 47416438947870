.itemRow {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        'productData productData'
        'qtyData spltQtyData'
        'method method'
        'vendorPrice vendorPrice'
        'itemPrice totalPrice';
}

.itemRow.parent {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        'productData productData'
        'qtyData totalPrice';
}

.itemRow.parent {
    font-weight: bold;
}

.itemRow.child .productData a {
    font-weight: 400;
}

.itemRow .productData {
    grid-area: productData;
    flex-direction: row;
}

.itemRow .productData > div:last-child {
    flex-shrink: 1;
}

.itemRow .qtyData {
    grid-area: qtyData;
}

.itemRow .qtyData > div {
    display: flex;
    flex-direction: column;
}

.itemRow .spltQtyData {
    grid-area: spltQtyData;
    justify-content: center;
}

.itemRow .spltQtyData input {
    width: 100%;
}

.itemRow .method {
    grid-area: method;
    flex-direction: column;
    align-items: stretch;
}

.itemRow .itemPrice {
    grid-area: itemPrice;
}

.itemRow .vendorPrice {
    grid-area: vendorPrice;
}

.itemRow .totalPrice {
    grid-area: totalPrice;
}

.itemRow .methodButtonsWrapper {
    display: flex;
    gap: var(--sn-space-2);
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    width: auto;
}

@media (min-width: 768px) {
    .itemRow {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-areas:
            'productData productData productData productData'
            'qtyData spltQtyData itemPrice totalPrice'
            'method method method vendorPrice';
    }

    .itemRow.parent {
        grid-template-columns: auto 150px 150px;
        grid-template-areas: 'productData qtyData totalPrice';
    }

    .itemRow .methodButtonsWrapper {
        flex-direction: row;
        justify-content: flex-start;
    }

    .itemRow .vendorPrice {
        justify-content: flex-end;
    }

    .itemRow .qtyData,
    .itemRow .spltQtyData,
    .itemRow .itemPrice,
    .itemRow .totalPrice {
        justify-content: flex-start;
    }
}

@media (min-width: 992px) {
    .itemRow > div {
        padding: var(--sn-space-1) !important;
    }

    .itemRow {
        grid-template-columns: 120px auto 120px 120px 120px;
        grid-template-areas:
            'productData  productData qtyData itemPrice totalPrice'
            'spltQtyData method method vendorPrice vendorPrice';
    }

    .itemRow .spltQtyData {
        justify-content: flex-end;
    }

    .itemRow .spltQtyData:empty {
        padding: 0 !important;
        width: 0;
        height: 0;
    }

    .itemRow .methodButtonsWrapper {
        gap: var(--sn-space-1);
    }

    .itemRow .methodButtonsWrapper > button:global(.outline) > span:last-child {
        display: none;
    }
}

@media (min-width: 992px) and (max-width: 1439px) {
    .itemRow .spltQtyData {
        transform: translate(120px, -5px);
        width: 85px;
    }
    .itemRow .spltQtyData.withOptionSelected {
        transform: translate(120px, -60px);
    }

    .itemRow .spltQtyData.withDropshipSelected {
        transform: translate(120px, -85px);
    }

    .itemRow .spltQtyData input {
        height: 38px;
    }

    .itemRow .methodButtonsWrapper {
        flex-wrap: nowrap;
    }

    .itemRow .methodButtonsWrapper.withSplit {
        padding-left: 80px;
        margin-right: -80px;
        margin-top: 25px;
    }

    .itemRow .methodButtonsWrapper.withVendorPrice {
        margin-bottom: 25px;
    }
}

@media (max-width: 1859px) {
    .itemRow[class*='headerRow'] {
        display: none;
    }

    .itemRow.parent .spltQtyData,
    .itemRow.parent .method,
    .itemRow.parent .itemPrice,
    .itemRow.parent .vendorPrice {
        padding: 0 !important;
    }

    .itemRow > div:before {
        content: attr(data-label);
        display: block;
    }

    .itemRow .qtyData,
    .itemRow .spltQtyData,
    .itemRow .vendorPrice,
    .itemRow .itemPrice,
    .itemRow .totalPrice {
        flex-direction: column;
    }
}

@media (min-width: 1220px) {
    .itemRow > div {
        padding: var(--sn-space-2) !important;
    }
    .itemRow .methodButtonsWrapper > button:global(.outline) > span:last-child {
        display: initial;
    }

    .itemRow .spltQtyData {
        width: 100px;
    }
}

@media (min-width: 1440px) {
    .itemRow,
    .itemRow.parent {
        grid-template-columns: auto 130px 130px 120px 120px !important;
        grid-template-areas:
            'productData qtyData spltQtyData itemPrice totalPrice'
            'method method method vendorPrice vendorPrice ';
    }

    .itemRow .spltQtyData {
        justify-content: flex-start;
        align-items: center;
        width: auto;
    }

    .itemRow .spltQtyData input {
        margin-bottom: 0;
    }

    .itemRow .method,
    .itemRow .vendorPrice {
        margin-top: 0;
    }
}

@media (min-width: 1860px) {
    .itemRow,
    .itemRow.parent {
        grid-template-columns: 350px 130px 130px minmax(570px, auto) 140px 120px 120px !important;
        grid-template-areas: 'productData qtyData spltQtyData method vendorPrice itemPrice totalPrice';
        width: fit-content !important;
        min-width: 100%;
    }

    .itemRow .spltQtyData,
    .itemRow .method {
        padding-left: var(--sn-space-2) !important;
    }

    .itemRow .spltQtyData label {
        display: none;
    }

    .itemRow .itemPrice,
    .itemRow .totalPrice {
        justify-content: flex-end;
        flex-direction: row;
    }

    .itemRow .vendorPrice {
        align-items: flex-end;
    }

    .itemRow .vendorPrice > div {
        margin-bottom: 22px;
    }

    .itemRow .vendorPrice label {
        display: none;
    }
}
