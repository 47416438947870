.spinner {
    position: absolute;
    inset: 0;
    margin: auto;
}

.itemsWrapper {
    margin: var(--sn-space-3) calc(var(--sn-space-2) * -1) calc(var(--sn-space-4) * -1);
    overflow: auto;
}

.itemRow {
    position: relative;
    grid-template-columns: minmax(350px, auto) 270px 220px 120px 100px !important;
    grid-template-areas: 'productData qtyData priceData totalPriceData actions';
    width: fit-content !important;
    min-width: 100%;
}

.itemRow > div {
    padding: var(--sn-space-2) !important;
}

.itemRow.parent {
    font-weight: bold;
}

.itemRow.child .productData a {
    font-weight: 400;
}

.itemRow .productData {
    grid-area: productData;
    flex-direction: row;
}

.itemRow .productData > div:last-child {
    flex-shrink: 1;
}

.itemRow .qtyData {
    grid-area: qtyData;
    flex-direction: row;
    justify-content: flex-start;
}

.itemRow .qtyData input {
    width: 100px;
    margin-right: var(--sn-space-05);
}

.itemRow .priceData {
    grid-area: priceData;
}

.itemRow .totalPriceData {
    grid-area: totalPriceData;
    justify-content: flex-end;
    flex-direction: row;
}

.itemRow .actions {
    grid-area: actions;
}

@media (min-width: 768px) {
    .itemsWrapper {
        margin-left: calc(var(--sn-space-3) * -1);
        margin-right: calc(var(--sn-space-3) * -1);
    }
}
