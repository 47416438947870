.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkIcon {
    font-size: var(--sn-title-md-font-size);
    color: var(--sn-success);
}

.trashButton {
    display: none !important;
}

.wrapper:hover > .checkIcon {
    display: none;
}

.wrapper:hover > .trashButton {
    display: block !important;
}

.confirmationWrapper {
    position: absolute;
    inset: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    padding: 0 16px;
}
