.isChild {
    padding-left: var(--sn-space-3);
}

.isChild a {
    font-weight: 400;
}

.isParent {
    font-weight: bold;
}

.price {
    text-align: right;
}
