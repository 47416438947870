.actionsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 24px;
}

.replaceWrapper {
    flex-grow: 1;
}

.vendorWrapper {
    position: relative;
    display: flex;
    gap: 16px;
    flex-direction: row;
    flex-wrap: nowrap;
}

.vendorWrapper > div:first-child {
    flex: 1 1 auto;
}

.vendorWrapper > div:last-child {
    flex: 0 0 120px;
}

.vendorPriceWrapper {
    position: relative;
}

.vendorSpinner {
    position: absolute;
    inset: 0;
    margin: 40px auto 0;
}

.cancellationWrapper {
    flex: 0 0 130px;
    display: flex;
    justify-content: flex-end;
}

.replaceWrapper + .cancellationWrapper {
    padding-top: 32px;
}
