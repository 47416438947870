@media (min-width: 576px) {
    .modal {
        max-width: 95%;
    }
}

.itemsWrapper {
    overflow: auto;
}

.itemRow {
    position: relative;
    grid-template-columns: 50px 400px 120px 220px 170px minmax(600px, auto) !important;

    grid-template-areas: 'checkboxData productData qtyData splitQtyData priceData actions';
    width: fit-content !important;
    min-width: 100%;
}
.itemRow .checkboxData {
    grid-area: checkboxData;
}
.itemRow .productData {
    grid-area: productData;
}

.itemRow .qtyData {
    grid-area: qtyData;
    justify-content: center;
}

.itemRow .qtyData .qtyWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.itemRow .splitQtyData .splitQtyWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.splitErrorMessage {
    margin: -10px 0 0;
    font-size: 80%;
    white-space: nowrap;
    color: var(--sn-danger);
}

.itemRow .splitQtyData .splitQtyInputWrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.itemRow .splitQtyData .splitQtyInputWrapper > button {
    width: 50px;
    padding: 0 0px;
    flex-shrink: 0;
}

.itemRow .splitQtyData .splitQtyInputWrapper > button > span:last-child {
    display: none;
}

.itemRow .splitQtyData {
    grid-area: splitQtyData;
}

.itemRow .priceData {
    grid-area: priceData;
}

.itemRow .priceData input {
    max-width: 100%;
}

.itemRow .actions {
    grid-area: actions;
}
